<template>
  <div class="container ">

    <div class="modal-border">
      <h1 class="mt-2">How Can We Help?</h1>
      <section class="row  ">
        <div class="col-5  hide-logo">
          <div>
            <img class="img-fluid logo-size mt-5" src="../assets/AMSGLogoFinal4Sure.png" alt="">
          </div>
        </div>
        <div class=" col-lg-7  ">
          <form>
            <input v-model="editable.name" type="text" class="form-control input-bg mt-1 mb-3 input-control input-size "
              id="formGroupExampleInput" maxlength="50" placeholder="Name">
            <input v-model="editable.phone" type="text" class="form-control input-bg mb-3 input-control "
              id="formGroupExampleInput2" maxlength="50" placeholder="Phone">
            <input v-model="editable.email" type="text" class="form-control input-bg mb-3 input-control "
              id="formGroupExampleInput3" maxlength="50" placeholder="Email">
            <select v-model="editable.type" class=" form-select input-bg mb-3 input-control " id="formGroupExampleInput4">
              <option class="option-text" value="null" selected>Choose Type of Communication</option>
              <option class="option-text" value="1">Contact Us</option>
              <option class="option-text" value="2">Join Us</option>
              <option class="option-text" value="3">Hire Us</option>
            </select>
            <textarea v-model="editable.description" class="input-bg  form-control mb-3 input-control "
              id="formGroupExampleInput5" placeholder="Brief Description" rows="10" maxlength="450"></textarea>
            <div class="mb-3 ">
              <button class="me-2 btn btn-secondary" data-bs-dismiss="modal" @click.prevent="sendMail">Submit</button>
              <button class=" btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
          </form>
        </div>

      </section>
    </div>
  </div>
</template>


<script>
import { ref } from "vue";


export default {
  setup() {
    const editable = ref({});






    return {
      editable,
      sendMail() {
        // eslint-disable-next-line no-undef
        Email.send({
          Host: "	smtp.elasticemail.com",
          Username: "rachaelranae@gmail.com",
          Password: "E6F920834BCCEFCCD8433EE312E3598263C1",
          To: "liamj.smith@hotmail.com, rachael@amsg-assoc.com",
          From: "rachael@amsg-assoc.com",
          Subject: "AMSG website contact",
          Body: editable.value
        })
        editable.value = {}
      }


    }
  }
};
</script>


<style lang="scss" scoped>
.input-bg {
  background-color: #2c3e50;
  border: 1px solid #989695;
  color: #ffffff;
}

::placeholder {
  color: #ffffff;
}

.select-text {
  color: #ffffff !important;
}

.option-text {
  color: #ffffff;
}


@media only screen and (min-width:991px) {
  .input-control {
    width: 95%;
  }
}

.logo-size {
  max-width: 200px;
  max-height: 400px;
  border-radius: 100%;
}

.modal-border {
  background-color: #989695;
  border: 10px solid hsl(0, 1%, 39%);
  border-radius: 5px;
  padding: 8px;
}

// NOTE hides logo when screen size is bellow 991px was having layout issues at that size
@media only screen and (min-width:0px) and (max-width:991px) {
  .hide-logo {
    display: none;
  }
}

.container {
  padding: 0;
  margin: 0 auto;
}
</style>


