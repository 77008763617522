import { createRouter, createWebHistory } from "vue-router";
import Home from "@/pages/HomePage.vue";
import Vendors from "@/pages/VendorPage.vue";
import About from "./pages/AboutPage.vue";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/Vendors',
    name: 'Vendors',
    component: Vendors,
  },
  {
    path: '/About',
    name: 'About',
    component: About,
  }
]

export const router = createRouter({
  history: createWebHistory(),
  routes
})