<template>
  <section class="custom-height row align-items-center justify-content-around p-0">
    <div class="col-12">
      <h1 class=" justify-content-around my-2 p-0 mb-1">Our Story</h1>
    </div>
    <div class="col-12">
      <h4 class="mb-5">
        AMSG and Associates has represented luxury plumbing, bath, and hardware in the Pacific Northwest since 1992. Our
        customer group includes architect and designer specification assistance, wholesaler, dealer, and contractor
        support. We focus on integrity, exceptional customer service, and providing long term, profitable relationships
        for our principals and customers.
      </h4>
    </div>
    <div class="col-12 align-items-center justify-content-around p-0">
      <h1 class="justify-content-around p-0 my-1 mb-5">The Team</h1>
      <div class=" p-0"></div>
    </div>
    <div class="col-12 justify-content-evenly">
      <section class="row">
        <div class="col-md-3 p-0 mb-2">
          <img class="img-border" src="../assets/Row.png" alt="">
          <h2>Roland</h2>
        </div>
        <div class="col-md-3 p-0">
          <img class="img-border" src="../assets/rachael.png" alt="">
          <h2>Rachael</h2>
        </div>
        <div class="col-md-3 p-0">
          <img class="img-border" src="../assets/Cathy.png" alt="">
          <h2>Cathy</h2>
        </div>
        <div class="col-md-3 p-0">
          <img class="img-border" src="../assets/Christy.png" alt="">
          <h2>Christy</h2>
        </div>
      </section>
    </div>
  </section>
</template>


<script>

export default {
  setup() {
    return {}
  }
};
</script>


<style lang="scss" scoped>
.title-font {
  font-family: Lora text;
  font-size: 10vh;
}

.title-border {
  border-bottom: 1px solid black;
  max-width: 80%;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.img-border {
  border-radius: 180%;
  border: .25rem solid #989695;

}
</style>