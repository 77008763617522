<template>
  <div class="container-fluid m-0">
    <section class="row">
      <div class="col-12 ">
        <p class="text-color mt-3">Office phone: 1-800-285-4622</p>
        <p class="text-color ">Copyright amsg-associates LLC</p>
        <span class=" d-flex text-color ms-2 text-start">Powered by: IronShield Tech LLC</span>
      </div>
    </section>

  </div>
</template>


<script>

export default {
  setup() {
    return {}
  }
};
</script>


<style lang="scss" scoped>
.text-color {
  color: #111011;
  font-size: 100%;
}
</style>