<template>
  <section id="home" class="row">
    <div class="col-12 ">
      <Carousel />
    </div>
  </section>
</template>


<script>
import Carousel from "@/components/Carousel.vue";
import { onMounted } from "vue";



export default {
  setup() {
    onMounted(() => {
    });
    return {};
  },
  components: { Carousel }
};
</script>


<style lang="scss" scoped>
#home {
  flex-grow: 1;
}
</style>
