<template>
  <div class="container-fluid">
    <section class="row d-flex align-items-center justify-content-around p-0">
      <div class=" col-12 justify-content-around d-flex p-0 mb-1">
        <h1 class="title-font  title-font-large">Manufacturers</h1>
      </div>
    </section>
    <section class="row justify-content-around">
      <div class="d-flex col-md-4 btn align-items-center" title="https://www.barclayproducts.com">
        <a href="https://www.barclayproducts.com" class="text-dark">
          <section class="row justify-content-center">
            <h3>
              Barclay
            </h3>
          </section>
          <section class="row justify-content-center align-items-center bg-dark m-3 vender-logo-rectangle">
            <img class="" src="../assets/barclay-logo.webp" alt="">
          </section>
          <section class="row justify-content-center vender-slogan">
            <p>
              Innovative and decorative kitchen and bath options featuring designer driven products from around the
              globe
            </p>
          </section>
        </a>
      </div>
      <div class="d-flex col-md-4  btn align-items-center" title="https://www.calfaucets.com">
        <a href="https://www.calfaucets.com/" class="text-dark">
          <section class="row justify-content-center align-items-center">
            <h3>
              California Faucets
            </h3>
          </section>
          <section class="row bg-dark m-3 vender-logo-rectangle">
            <img class="p-4 bg-softWhite" src="../assets/california-faucets.webp" alt="">
          </section>
          <section class="row  justify-content-center vender-slogan ">
            Family owned since 1988 featuring artisan-crafted kitchen and bath faucets renown for outstanding quality
            and exceptional design
          </section>
        </a>
      </div>
    </section>
    <section class="row d-flex justify-content-around p-0">
      <div class="d-flex col-md-4  btn align-items-center" title="https://www.gatco-inc.com">
        <a href="https://www.gatco-inc.com" class="text-dark">
          <section class="row justify-content-center">
            <h3>
              Gatco
            </h3>
          </section>
          <section class="row  bg-dark m-3 vender-logo-rectangle">
            <img class="p-2 bg-softWhite" src="../assets/gatco-logo.avif" alt="">
          </section>
          <section class="row justify-content-center vender-slogan">
            Luxury bath hardware for homeowners and hospitality focused on design, quality, and longevity since 1977
          </section>
        </a>
      </div>
      <div class="d-flex col-md-4 m-2 btn align-items-center" title="https://www.stoneforest.com">
        <a href="https://www.stoneforest.com" class="text-dark ">
          <section class="row justify-content-center">
            <h3>
              Stone Forest
            </h3>
          </section>
          <section class="row m-3 vender-logo">
            <img class="py-2 bg-softWhite vender-logo-square" src="../assets/stone-forest-logo.png" alt="">
          </section>
          <section class="row justify-content-center vender-slogan">
            With design ingenuity and passion, Stone Forest brings elegant simplicity and the timeless beauty of natural
            materials to your kitchen, bath, and garden
          </section>
        </a>
      </div>
    </section>
    <section class="row d-flex justify-content-around p-0">
      <div class="d-flex col-md-4  btn align-items-center" title="https://www.vitraglobal.com/">
        <a href="https://www.vitraglobal.com/" class="text-dark">
          <section class="row justify-content-center">
            <h3>
              Vitra
            </h3>
          </section>
          <section class="row m-3">
            <img class="p-2" src="../assets/Vitra-logo.png" alt="">
          </section>
          <section class="row justify-content-center vender-slogan">
            Global company featuring sustainable, perfect kitchen and bath solutions since 1942.
          </section>
        </a>
      </div>
    </section>
  </div>
</template>


<script>

export default {
  setup() {
    return {}
  }
};
</script>


<style lang="scss" scoped>
.description-text {
  font-size: 120%;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.text-underline:hover {
  border-bottom: 1px solid black;
}

@media only screen and (max-width: 991px) {
  .vender-logo-square {
    min-height: 10vh;
    min-width: 20vh;
  }
}

.vender-slogan {
  min-height: 10vh;
  font-size: 120%;
  color: #111011;
}

@media only screen and (min-width: 992px) {

  .vender-logo-rectangle {
    min-height: 25vh;
    min-width: 30vh;
  }
}

.bg-softWhite {
  background-color: rgb(248, 243, 243);
}

.title-font {
  font-family: Lora text;
}

@media only screen and (min-width: 768px) {

  .title-font-large {
    font-size: 10vh;
    font-family: Lora text;
    font-size: 10vh;
  }

}

.title-border {
  border-bottom: 1px solid black;
  max-width: 80%;
  align-items: center;
  justify-content: center;
}
</style>