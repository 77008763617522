<template>

  <header id="header" class="sticky-top color-idea3 container-fluid">
    <Navbar />
  </header>

  <main class=" bg-color container-fluid">
    <router-view />
  </main>


  <Footer class="color-idea3">
  </Footer>

  <Modal id="contactForm">
    <ContactForm />
  </Modal>
</template>
<script>

import Navbar from "./components/Navbar.vue";
import Modal from "./components/Modal.vue"
import ContactForm from "./components/ContactForm.vue"
import Footer from "./components/Footer.vue";
import { computed, onMounted } from "vue";
import { AppState } from "./appState"

export default {
  setup() {
    onMounted(() => {
      setScrollVar();

    })

    window.addEventListener("scroll", setScrollVar)
    window.addEventListener("resize", setScrollVar)
    function setScrollVar() {
      const templateElement = document.documentElement
      const scrollCount = templateElement.scrollTop / templateElement.clientHeight
      console.log(Math.min(scrollCount * 100, 100))
      templateElement.style.setProperty(
        "--scroll",
        Math.min(scrollCount * 100, 100),
      )
      if (scrollCount >= 1) {
        document.getElementById("header").classList.add("navbar-fade", "sticky-top");
      } else {
        document.getElementById("header").classList.remove("navbar-fade");
      }
    }




    return {
      appState: computed(() => AppState),
      scrollCount: computed(() => AppState.scrollCount)

    }
  },
  components: { Navbar, Modal, ContactForm, Footer }

}
</script>

<style>
#app {
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  font-family: Lora;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #18191b;
  margin: 0;
}


* {
  font-family: Lora;
}


header {
  margin: 0;
  padding: 0;
  min-height: 10dvh;
}

main {
  display: flex;
}

.navbar-fade {
  opacity: 0;
}

main {
  flex-grow: 1;
}


.footer {
  place-content: center;
  position: absolute;
  min-height: 10dvh;
}

.pointer {
  cursor: pointer;
}

.default {
  cursor: default;
}

.bg-color {
  background-color: hsl(0, 1%, 39%);
}

.color-idea1 {
  background-color: #91a293;
}

.color-idea2 {
  background-color: #8e8578;
}

.color-idea3 {
  background-color: #989695;
}

.color-idea4 {
  background-color: #5c575a;
}

.color-idea5 {
  background-color: #c6bcab;
}
</style>
