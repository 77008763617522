<template>
  <div class="modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content ">
        <slot></slot>
      </div>
    </div>
  </div>
</template>


<script>

export default {
  setup() {



    return {}
  }
};
</script>


<style lang="scss" scoped></style>