<template>
  <div class="component ">
    <section class="row carousel-size" aria-label="Newest Photos">
      <div class="carousel col-12" data-carousel>
        <button class="carousel-button prev" data-carousel-button="prev" @click="prevSlide">&#8656;</button>
        <button class="carousel-button next" data-carousel-button="next" @click="nextSlide">&#8658;</button>
        <ul data-slides>
          <li v-for="(image, index) in images" :key="image" :data-active="currentSlide == index"
            class="slide d-flex justify-content-center">
            <div class="mt-5 carousel-text carousel-text-higher m-0">
              <h1 class="m-0 ">AMSG and Associates</h1>
            </div>
            <img class="img-size" :src="image" alt="">
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>


<script>
import { onMounted, ref } from "vue";
import calfaucetsBathroom from "../assets/calfaucets-bathroom.jpeg"
import gatcoBathroom from "../assets/gatco-bathroom.webp"
import barclayCarousel from "../assets/barclayCarousel.webp"
import calfaucetsKitchen from "../assets/calfaucets-kitchen.jpeg"
import bathroom from "../assets/bathroom.jpg"
import stoneTub from "../assets/stone-forest-bathtub.webp"
import beachBathroom from "../assets/Gatco-BeachBathroom.webp"
import calfaucetsSink from "../assets/CalFaucets-BathroomSink-D.jpg"
import barclayTub from "../assets/Barclay-PaintedTub.jpg"
import vitraReboot from "../assets/Vitra-kitchen-reboot.jpg"
import vitraSink from "../assets/Vitra-Kitchen-Sink-Closeup.jpg"


export default {
  setup() {
    onMounted(() => {
      interval.value = setInterval(nextSlide, 8000)
    })
    const interval = ref(null)
    const images = [
      stoneTub, calfaucetsBathroom, gatcoBathroom, barclayCarousel, vitraReboot, calfaucetsKitchen, bathroom, beachBathroom, barclayTub, calfaucetsSink, vitraSink]
    const currentSlide = ref(0)
    function nextSlide() {
      currentSlide.value = (currentSlide.value + 1) % images.length
    }
    function prevSlide() {
      currentSlide.value = (currentSlide.value - 1) % images.length
    }

    return {
      nextSlide,
      prevSlide,
      currentSlide,
      images
    }
  }
};
</script>

<style lang="scss" scoped>
*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
}

.carousel-size {
  height: 80vh;
  max-height: 100%;
}

.carousel {
  // width: 100%;
  // height: 100%;
  position: relative;
}

.carousel>ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

@media only screen and (min-width:991px) {
  .carousel-text {
    position: absolute;
    z-index: 2;
    background: none;
    border: none;
    font-size: 4rem;
    color: rgba(227, 220, 220, 0.8);
    cursor: pointer;
    border-radius: .25rem;
    padding: 0 .5rem;
    background-color: rgba(13, 13, 13, 0.5);
  }
}

@media only screen and (min-width:0px) and (max-width:991px) {
  .carousel-text-higher {
    position: absolute;
    z-index: 2;
    background: none;
    border: none;
    font-size: 4rem;
    color: rgba(227, 220, 220, 0.8);
    cursor: pointer;
    border-radius: .25rem;
    padding: 0 .5rem;
    background-color: rgba(13, 13, 13, 0.5);
  }
}


.slide {
  position: absolute;
  inset: 0;
  opacity: 0;
  transition: 400ms opacity ease-in-out;
  transition-delay: 200ms;
}

.slide>img {
  display: block;
  width: 100%;
  min-height: 100%;
  object-fit: contain;
  object-position: center;
}

.slide[data-active=true] {
  opacity: 1;
  z-index: 1;
  transition-delay: 0ms;
}


.carousel-button {
  position: absolute;
  z-index: 2;
  background: none;
  border: none;
  font-size: 3rem;
  top: 50%;
  transform: translateY(-50%);
  color: rgba(35, 32, 32, 0.5);
  cursor: pointer;
  border-radius: .25rem;
  padding: 0 .5rem;
  background-color: rgba(0, 0, 0, .1);
}

.carousel-button:hover {
  color: rgb(182, 175, 175);
  background-color: rgba(0, 0, 0, .2);
  outline: 1px solid black;
}


.carousel-button.prev {
  left: 2rem;
}

.carousel-button.next {
  right: 2rem;
}
</style>