<template>
  <nav class="navbar navbar-expand-md">
    <div class="container-fluid ">
      <section class="navbar-brand ms-5 ">
        <router-link to="/">
          <img class="logo" src="../assets/AMSGLogoFinal4Sure.png" alt="">
        </router-link>
      </section>
      <button class="navbar-toggler custom-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon "></span>
      </button>


      <!-- This is full navbar row -->
      <div class="collapse navbar-collapse me-5 mt-5 justify-content-end" id="navbarNav">
        <ul class="navbar-nav justify-content-end pe-0 me-0">
          <li class="nav-item">
            <a class="nav-link pointer text-white text-end" aria-current="Home" href="/"><span
                class="text-color button-border pb-0 mx-1 underline" data-bs-target="#navbarNav"
                data-bs-toggle="collapse">Home</span></a>
          </li>
          <li class="nav-item">
            <a class=" nav-link pointer text-white text-end" aria-current="Manufacturers" href="/Vendors">
              <span class="text-color  pb-0 mx-1 " data-bs-target="#navbarNav"
                data-bs-toggle="collapse">Manufacturers</span></a>
          </li>
          <li class="nav-item">
            <a class="nav-link pointer text-white text-end" aria-current="About" href="/About"><span
                class=" text-color button-border pb-0 mx-1 underline" data-bs-target="#navbarNav"
                data-bs-toggle="collapse">The Team</span></a>
          </li>
          <li class="nav-item">
            <a class="nav-link pointer text-white text-end" data-bs-toggle="modal" data-bs-target="#contactForm"><span
                class=" text-color button-border pb-0 mx-1 underline" data-bs-target="#navbarNav"
                data-bs-toggle="collapse">Contact Us</span></a>
          </li>

        </ul>
      </div>
    </div>

  </nav>
</template>


<script>

export default {
  setup() {



    return {


    }
  }
};
</script>


<style lang="scss" scoped>
.navbar-mine {
  height: 10%;
  border-bottom: 4px solid black;
}

.button-style-two {
  border: 2px solid #5c575a;
  z-index: 3;
}

.text-color {
  color: #111011;
  font-size: 140%;
}

.text-color:hover {
  color: #2c3e50;
}

li:hover {
  transform: scale(1.2);
}

.logo {
  max-height: 100px;
  border-radius: 100%;
}
</style>